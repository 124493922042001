import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import { NewsList } from '@mangoart/gatsby-ui/components/ezagrar/NewsList';
import DefaultLayout from '../layout/DefaultLayout';

const NewsPage = ({ data, location }) => {
  const { pageData, siteData, news } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>{content.title}</PageTitle>
      <NewsList data={news} />
    </DefaultLayout>
  );
};

export default NewsPage;

export const NewsPageQuery = graphql`
  query NewsPageQuery {
    news: allCockpitNews(filter: { published: { eq: true } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          teaser
          date
          expirationdate
          customurl
          teaserimage {
            childImageSharp {
              gatsbyImageData(height: 180, aspectRatio: 2)
            }
          }
          images {
            alttext
            image {
              childImageSharp {
                gatsbyImageData(height: 180, aspectRatio: 2)
              }
            }
          }
        }
      }
    }
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageNews {
      content {
        title
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
